<script>
import Layout from "../../../layouts/main.vue";
// import PageHeader from "@/components/page-header";
import axios from "axios";

export default {
  page: {
    title: "API",
  },
  methods: {
    showModal(groupId, groupName) {
      if (typeof groupId !== 'undefined') {
        document.getElementById('create-btn').click();
        document.getElementById('add-new-modal').innerHTML = this.$t('t-config-group-title-edit');
        document.getElementById('group-id').value = groupId;
        document.getElementById('group-name').value = groupName;
        document.getElementById('add-btn').style.display = 'none';
        document.getElementById('update-btn').style.display = 'block';
      } else {
        document.getElementById('add-new-modal').innerHTML = this.$t('t-config-group-title-new');
        document.getElementById('group-id').value = '';
        document.getElementById('group-name').value = '';
        document.getElementById('add-btn').style.display = 'block';
        document.getElementById('update-btn').style.display = 'none';
      }
    },
    handleGroupChange() {
      this.selectedType = null;
      this.loadConfig();
    },
    handleSearchInput(event) {
      this.searchQuery = event.target.value;
    },
    apiDocs: function() {
      let anchor = document.createElement('a');
      anchor.href = process.env.VUE_APP_API_BASE_URL+'/platform/swagger-ui';
      anchor.target="_blank";
      anchor.click();
    },
    apiKeyCreate: function() {
      const data = {}
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/apikey/create`, data)
          .then((data) => {
            this.apiKey = data.data.key;
          }).catch((er) => {
        console.log(er)
      });
    },
    apiKeyDelete: function() {
      const data = {}
      axios.post(process.env.VUE_APP_API_BASE_URL+`/management/v1.0.0/apikey/delete`, data)
          .then(() => {
            this.apiKey = null;
          }).catch((er) => {
        console.log(er)
      });
    },
    loadConfig() {
      axios.get(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/api/list').then((data) => {
        this.apiKey = data.data.key;
      }).catch((er) => {
        console.log(er)
      });
    },
  },
  data() {
    return {
      title: this.$t("t-menu-configuration"),
      apiKey: null,
    };
  },
  beforeMount() {
  },
  mounted() {
    this.loadConfig();
  },
  components: {
    Layout,
    // PageHeader,
  },
};
</script>

<template>
  <Layout>
    <br>
    <div class="tab-content">
      <div class="tab-pane active" id="nav-api" role="tabpanel">
        <div class="row">
          <div class="col-xxl-6 col-lg-6">
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">
                  {{ $t("t-menu-api") }}
                </h4>
                <div class="flex-shrink-0">
                  <button v-if="apiKey == null" type="button" class="btn btn-success add-btn" id="apikey-btn" @click="apiKeyCreate()">
                    <i class="ri-add-line align-bottom me-1"></i> {{ $t("t-api-new") }}
                  </button>
                  <button v-if="apiKey != null" type="button" class="btn btn-danger add-btn" id="apikey-btn" @click="apiKeyDelete()">
                    <i class="ri-delete-bin-line align-bottom me-1"></i> {{ $t("t-api-delete") }}
                  </button>
<!--                  <button type="button" class="btn btn-info add-btn ms-1" @click="apiDocs()">-->
<!--                    <i class="ri-information-line align-bottom"></i> {{ $t("t-api-docs") }}-->
<!--                  </button>-->
                </div>
              </div>
              <!-- end card-header -->
              <div class="card-body">
                <div v-if="apiKey != null">
                  <p>
                    {{ $t("t-api-title") }}
                  </p>
                  <code>
                    {{ apiKey }}
                  </code>
                </div>
                <div v-if="apiKey == null">
                  {{ $t("t-api-none") }}
                </div>
              </div>
            </div>
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>

  </Layout>
</template>